.table-header {
  text-align: left;
}

.rotate-cell {
  font-size: 0.8rem;
  // max-height: 100px;
  // max-width: 1rem;
  vertical-align: bottom !important;
  width: 20px;
  height: 140px;

  .crew-table & {
    padding: 6px 3px;
  }
}

.my-cell {
  font-size: 0.8rem;
  width: 20px;
  height: 140px;

  .crew-table & {
    padding: 6px 3px;
  }
}

.rotate-cell .rotate {
  transform: rotate(-90deg);
  // display: inline-block;
  // text-align: left;
  white-space: nowrap;
  height: 20px;
  width: 20px;
}

.rotate-cell .rotate .inner {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}

.cell {
  border-right: 1px solid rgba(224, 224, 244, 1);
}

.subcat {
  border-left: 1px solid grey;
  padding: 6px;

  span {
  }
}

.crew-table {
  th,
  tbody td:first-of-type {
    padding: 6px;
  }

  thead th:not(:first-of-type),
  tbody td:first-of-type {
    cursor: pointer;
  }
}

// TODO style properly
.co-details {
  z-index: 2500 !important;
  .MuiPopover-paper {
    padding: 15px;
    min-width: 250px;

    > a {
      display: block;
      margin-bottom: 10px;
      color: gray;
      font-size: smaller;
    }
  }

  .toolbar.right {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
  }
}

.co-details-history {
  z-index: 2501 !important;
}

// table tbody td {
//   border-right: 1px solid lightgrey;
//  white-space: nowrap;
// }
