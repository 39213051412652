.table-header {
  text-align: left;
}

.rotate-cell {
  font-size: 0.8rem;
  vertical-align: bottom !important;
  width: 20px;
  height: 140px;

  .crew-table & {
    padding: 6px 3px;
  }
}

.limitation-cell {
  background:
    linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)),
    repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 5px,
      rgba(255, 153, 0, 0.3) 5px,
      rgba(255, 153, 0, 0.3) 5.5px,
      #ee831f 6px,
      #ee831f 10px,
      rgba(255, 153, 0, 0.3) 8.5px,
      rgba(255, 153, 0, 0.3) 9px
    );
}

.rotate-cell .rotate {
  transform: rotate(-90deg);
  white-space: nowrap;
  height: 20px;
  width: 20px;
}

.rotate-cell .rotate .inner {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}

.cell {
  border-right: 1px solid rgba(224, 224, 244, 1);
}

.subcat {
  border-left: 1px solid grey;
  padding: 6px;
}

.crew-table {
  th,
  tbody td:first-of-type {
    padding: 6px;
  }

  thead th:not(:first-of-type),
  tbody td:first-of-type {
    cursor: pointer;
  }
}

.status-container {
  position: relative;
  display: inline-block;
  padding: 5px;
}
